import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/content/blog/2008-04-17-fuente-de-laboratorio/index.md";
import * as React from "react";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import {Link, graphql} from "gatsby";
import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PostImage from "../components/postimage";
import Webmentions from "../components/webmentions";
const BlogPostTemplate = ({data: {previous, next, site, mdx: post}, location, children}) => {
  const siteTitle = site.siteMetadata?.title || `Title`;
  return React.createElement(Layout, {
    location: location,
    title: siteTitle,
    menuLinks: site.siteMetadata.menuLinks
  }, React.createElement("article", {
    className: "blog-post h-entry",
    itemScope: true,
    itemType: "http://schema.org/Article"
  }, React.createElement("header", null, React.createElement("div", {
    className: "post-image"
  }, React.createElement("ul", {
    className: "tag-container"
  }, Array.isArray(post.frontmatter.tags) ? post.frontmatter.tags.map(tag => React.createElement("li", {
    key: tag
  }, tag)) : null), React.createElement(PostImage, {
    coverImage: post.frontmatter.coverImage
  })), React.createElement("h1", {
    itemProp: "headline",
    className: "p-name"
  }, post.frontmatter.title), React.createElement("time", {
    className: "dt-published",
    datetime: post.frontmatter.computerDate
  }, React.createElement("a", {
    className: "u-url",
    href: post.fields.slug
  }, post.frontmatter.humanDate))), React.createElement("div", {
    className: "e-content"
  }, children), React.createElement("hr"), React.createElement("footer", null, React.createElement(Bio, {
    className: "p-author"
  }))), React.createElement(Webmentions, {
    postUrl: site.siteMetadata.siteUrl + post.fields.slug
  }), React.createElement("nav", {
    className: "blog-post-nav"
  }, React.createElement("ul", {
    style: {
      display: `flex`,
      flexWrap: `wrap`,
      justifyContent: `space-between`,
      listStyle: `none`,
      padding: 0
    }
  }, React.createElement("li", null, previous && React.createElement(Link, {
    to: previous.fields.slug,
    rel: "prev"
  }, "← ", previous.frontmatter.title)), React.createElement("li", null, next && React.createElement(Link, {
    to: next.fields.slug,
    rel: "next"
  }, next.frontmatter.title, " →")))));
};
export const Head = ({data: {mdx: post}}) => {
  const ogImage = post.frontmatter.coverImage?.childImageSharp.gatsbyImageData;
  return React.createElement(Seo, {
    title: post.frontmatter.title,
    description: post.excerpt,
    coverImage: ogImage?.images.fallback.src,
    pageType: "article",
    date: post.frontmatter.computerDate
  }, React.createElement("link", {
    rel: "webmention",
    href: "https://webmention.io/cyborgspaces.com/webmention"
  }));
};
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title
        computerDate: date(formatString: "YYYY-MM-DD")
        humanDate: date(formatString: "MMMM DD, YYYY")
        coverImage {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        tags
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
