/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "ara empezar a hacerme un pequeño laboratorio de electronica he decidido hacerme una fuente de alimentación para esto he usado una vieja fuente de alimentación de un pc viejo. Como no encontraba un buen chasis donde meterla y a mi me gustan las cosas utiles y rapidas decidí hacerla en una caja de zapatos, así que me cojí una y me puse manos a la obra."), "\n", React.createElement(_components.p, null, "Materiales:"), "\n", React.createElement(_components.p, null, "-Fuente de alimentación de pc."), "\n", React.createElement(_components.p, null, "-Caja de zapatos."), "\n", React.createElement(_components.p, null, "-Conector de fuente (lo saque de una placa base)."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/04/conector_fuente.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 160px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABtXAwylNBX//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhEDIf/aAAgBAQABBQJNMuOdF4qXjp6Yf//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQETEh/9oACAEBAAY/AjtjSyMUf//EABsQAAMAAgMAAAAAAAAAAAAAAAABETFhIVGh/9oACAEBAAE/IUSr8NIbEbNTA7U7SN8FzCej/9oADAMBAAIAAwAAABAr3//EABYRAAMAAAAAAAAAAAAAAAAAABARUf/aAAgBAwEBPxB6P//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EIV//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARMSFBwf/aAAgBAQABPxBYfOAMhQ1CvlfkYwiktw6tD7AM+gGxtxP/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"conector fuente\"\n        title=\"conector fuente\"\n        src=\"/static/f690324404d2abc3ca8d51171ef14994/0913d/conector_fuente.jpg\"\n        srcset=\"/static/f690324404d2abc3ca8d51171ef14994/ff44c/conector_fuente.jpg 158w,\n/static/f690324404d2abc3ca8d51171ef14994/0913d/conector_fuente.jpg 160w\"\n        sizes=\"(max-width: 160px) 100vw, 160px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "-Conectores banana hembra para chasis(2 Negros y 5 Rojos)."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/04/banana_hembra.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 160px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBA//EABYBAQEBAAAAAAAAAAAAAAAAAAEAA//aAAwDAQACEAMQAAABxfHkclyP/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECAxMhIv/aAAgBAQABBQJzMHlmtFyqHhFxf//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwERf//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AVn/xAAZEAABBQAAAAAAAAAAAAAAAAAAAREgITH/2gAIAQEABj8CyFKMf//EABkQAAMBAQEAAAAAAAAAAAAAAAABETEhQf/aAAgBAQABPyGZdER4OmCdprhN4DwhcP/aAAwDAQACAAMAAAAQZO//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxCdWPT/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Qo2v/xAAbEAEBAQACAwAAAAAAAAAAAAABEQBBUSExgf/aAAgBAQABPxDx66MwZIUvCZtT81Y3C5GWHXGG8QHrf//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"banana hembra\"\n        title=\"banana hembra\"\n        src=\"/static/eebc8b1cb1f1727d22f029dd4f048e5e/0913d/banana_hembra.jpg\"\n        srcset=\"/static/eebc8b1cb1f1727d22f029dd4f048e5e/ff44c/banana_hembra.jpg 158w,\n/static/eebc8b1cb1f1727d22f029dd4f048e5e/0913d/banana_hembra.jpg 160w\"\n        sizes=\"(max-width: 160px) 100vw, 160px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "-Interruptor."), "\n", React.createElement(_components.p, null, "-Cable"), "\n", React.createElement(_components.p, null, "Herramientas:"), "\n", React.createElement(_components.p, null, "-Soldador."), "\n", React.createElement(_components.p, null, "-Polímetro."), "\n", React.createElement(_components.p, null, "-Cutter."), "\n", React.createElement(_components.p, null, "Lo primero que hice fue el frontal, me puse a hacer agujeros donde luego meteria los conectores banana y el interruptor para encender la fuente. Como no queria cortar los cables de la fuente por si algun dia la reutilizo desoldé el conector de una fuente de una placabase, es un trabajo que lleva su tiempo pero lo prefiero así."), "\n", React.createElement(_components.p, null, "Para encender una fuente ATX hay que cortocircuitar el cable verde con uno de los negros. Así que una vez con el conector ya puesto le soldé un interruptor de donde salia un cable negro y el verde."), "\n", React.createElement(_components.p, null, "Lo siguiente fue colocar todos los conectores banana y buscar en el conector de la fuente que patilla era cada tensión, así encontré patillas a +5, -5, +12, -12 y 3.3 V soldé cada cable con su correspondiente patilla y en la caja escribí la tensión de cada conector."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/04/conexiones_fuente.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 160px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAVWWmM8owf8A/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEhED/9oACAEBAAEFAp9L7pm2Shrs4P/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMAAwAAAAAAAAAAAAAAAAAQMRFBYf/aAAgBAQAGPwLGilXV/8QAGxABAAICAwAAAAAAAAAAAAAAAQARMVEhQWH/2gAIAQEAAT8hUNHRHbjblHsxmM9vCPqf/9oADAMBAAIAAwAAABCsL//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QmmP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMVFBgf/aAAgBAQABPxACNLchvmocyHuXMZEWmgigMh2+w092wq80X8ijtP/Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"conexiones fuente\"\n        title=\"conexiones fuente\"\n        src=\"/static/b6b338d20d5e496991df9c8c9e0d6055/0913d/conexiones_fuente.jpg\"\n        srcset=\"/static/b6b338d20d5e496991df9c8c9e0d6055/ff44c/conexiones_fuente.jpg 158w,\n/static/b6b338d20d5e496991df9c8c9e0d6055/0913d/conexiones_fuente.jpg 160w\"\n        sizes=\"(max-width: 160px) 100vw, 160px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/04/frontal_fuente.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 160px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABomZmdiYn/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEhEQP/2gAIAQEAAQUCfWaJxmlP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/AZiP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EABYQAQEBAAAAAAAAAAAAAAAAACEQIP/aAAgBAQAGPwJY4//EABkQAQEBAQEBAAAAAAAAAAAAABEAATEhQf/aAAgBAQABPyEAoN5bDjZAlniNfL//2gAMAwEAAgADAAAAEDA//8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQMBAT8QCm//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxBTH//EABsQAQEBAAIDAAAAAAAAAAAAAAERACExQXGh/9oACAEBAAE/EFWQHRklVy2J5QzfI9csFLN2j7N//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"frontal fuente\"\n        title=\"frontal fuente\"\n        src=\"/static/13ba1fd09570ca4891d12239aa11f33f/0913d/frontal_fuente.jpg\"\n        srcset=\"/static/13ba1fd09570ca4891d12239aa11f33f/ff44c/frontal_fuente.jpg 158w,\n/static/13ba1fd09570ca4891d12239aa11f33f/0913d/frontal_fuente.jpg 160w\"\n        sizes=\"(max-width: 160px) 100vw, 160px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Por la parte trasera le hice un agujero para ventilar y para colocar el enchufe y en la parte superior le recorte la parte donde daba el ventilador de la fuente."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/04/trasera_fuente.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 454px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdS9BRnMPC//xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIREiP/2gAIAQEAAQUC5hFL1xFRaeM4Z//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGMf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAcEAAABgMAAAAAAAAAAAAAAAAAAQIQESExkaH/2gAIAQEABj8ChQpBw18MYPbf/8QAHRAAAgIBBQAAAAAAAAAAAAAAAAERIWExQVFxgf/aAAgBAQABPyGW7GhDWt25MQwRnwNSEPJ6z//aAAwDAQACAAMAAAAQmA//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAEDAQE/EA3sP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EET/AP/EABwQAQACAgMBAAAAAAAAAAAAAAEAESFRMYGRsf/aAAgBAQABPxCoN5FLqVOADljuGp5HRLOKR8lJoRtDqoJlfc//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"ventilacion fuente\"\n        title=\"ventilacion fuente\"\n        src=\"/static/bd26d7e1e9675def3bad7254a9e17e3d/835f3/ventilacion_fuente.jpg\"\n        srcset=\"/static/bd26d7e1e9675def3bad7254a9e17e3d/ff44c/ventilacion_fuente.jpg 158w,\n/static/bd26d7e1e9675def3bad7254a9e17e3d/a6688/ventilacion_fuente.jpg 315w,\n/static/bd26d7e1e9675def3bad7254a9e17e3d/835f3/ventilacion_fuente.jpg 454w\"\n        sizes=\"(max-width: 454px) 100vw, 454px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), React.createElement(_components.img, {
    src: "http://litox9.files.wordpress.com/2008/04/ventilacion_fuente.jpg?w=455",
    alt: ""
  }))), "\n", React.createElement(_components.p, null, "Y ya está, ya tengo fuente, no es que tenga un gran acabado pero con esto por ahora me sobra, además no me a costado casi nada, lo mas caro han sido los conectores banana y la fuente la reciclé de un antiguo pc, ahora solo me faltan los cables banana-cocodrilo para empezar a trabajar con ella."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
